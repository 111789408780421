<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-07 16:17:43
 * @LastEditors: ------
 * @LastEditTime: 2022-02-25 13:23:12
-->
<template>
  <div class="info">
    <van-nav-bar
      title="客户信息"
      @click-left="$router.push('/customerInfo')"
      left-arrow
      safe-area-inset-top
      placeholder
    />
    <div class="userInfo">
      <div class="title">
        <van-icon name="friends-o" size="24px" color="#3573FD" />
        <span>家庭成员</span>
      </div>
      <!-- <van-form>
        <van-field
          v-model="contract.name"
          name="用户姓名"
          label="用户姓名"
          placeholder="请输入用户姓名 >"
          input-align="right"
        />
        <van-field
          v-model="contract.mobile"
          name="用户手机号"
          label="用户手机号"
          placeholder="请输入用户手机号 >"
          type="digit"
          input-align="right"
        />
        <van-field
          v-model="contract.idCard"
          name="用户身份证"
          label="用户身份证"
          placeholder="请输入用户身份证 >"
          input-align="right"
        />
      </van-form> -->

      <van-form>
        <van-field
          readonly
          clickable
          label="家庭标签"
          :value="value1"
          placeholder="请选择 >"
          input-align="right"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns1"
            @cancel="showPicker = false"
            @confirm="onConfirm2"
          />
        </van-popup>
        <van-field
          v-model="consumerFamilyMemberList.name"
          name="家属姓名"
          label="家属姓名"
          placeholder="请输入家属姓名 >"
          input-align="right"
        />
        <van-field
          v-model="consumerFamilyMemberList.mobile"
          name="家属手机号"
          label="家属手机号"
          placeholder="请输入家属手机号 >"
          type="tel"
          input-align="right"
        />
        <van-field
          v-model="consumerFamilyMemberList.idCard"
          name="家属身份证"
          label="家属身份证"
          placeholder="请输入家属身份证 >"
          input-align="right"
        />
        <van-field
          readonly
          clickable
          name="area"
          :value="value"
          label="所在城市"
          placeholder="省/市/区 >"
          input-align="right"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="consumerFamilyMemberList.street"
          name="居住地址"
          label="居住地址"
          placeholder="请输入服务对象居住地址 >"
          input-align="right"
        />
      </van-form>
      <div class="button">
        <van-button
          color="linear-gradient(-90deg, #6EAEFC, #6875F1)"
          round
          @click="save()"
          >保存客户信息</van-button
        >
        <van-button color="#CACFD7" round @click="$router.push('customerInfo')"
          >返回</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import { consumerInfo } from "@/api/index.js";

export default {
  data() {
    return {
      value: "",
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      contract: null,
      consumerFamilyMemberList: {
        kinship: "",
        name: "",
        idCard: "",
        mobile: "",
        province: "",
        city: "",
        county: "",
        detailAddress: "",
        street: "",
      },
      value1: "",
      columns1: ["父亲", "母亲", "爱人", "兄妹", "儿子", "女儿", "朋友"],
      showPicker: false,
    };
  },
  created() {
    this.contract = JSON.parse(this.$route.query.info);
    // console.log(JSON.parse(this.contract));
  },
  methods: {
    onConfirm(values) {
      //点击跳过保存时函数
      this.consumerFamilyMemberList.county = values[2].code;
      this.consumerFamilyMemberList.city =
        this.consumerFamilyMemberList.county.slice(0, 4);
      this.consumerFamilyMemberList.province =
        this.consumerFamilyMemberList.county.slice(0, 2);
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");

      this.showArea = false;
    },

    onConfirm2(value) {
      this.value1 = value;
      this.showPicker = false;
      if (value == "父亲") return (this.consumerFamilyMemberList.kinship = 10);
      if (value == "母亲") return (this.consumerFamilyMemberList.kinship = 11);
      if (value == "爱人") return (this.consumerFamilyMemberList.kinship = 12);
      if (value == "兄妹") return (this.consumerFamilyMemberList.kinship = 19);
      if (value == "儿子") return (this.consumerFamilyMemberList.kinship = 14);
      if (value == "女儿") return (this.consumerFamilyMemberList.kinship = 15);
      if (value == "朋友") return (this.consumerFamilyMemberList.kinship = 18);
    },
    async save() {
      let that = this;
      this.consumerFamilyMemberList.detailAddress =
        this.value + this.consumerFamilyMemberList.street;
      this.contract.consumerFamilyMemberList.push(
        this.consumerFamilyMemberList
      );

      //遍历对象属性值
      let isNull = false;
      // Object.values(this.consumerFamilyMemberList).forEach((val) => {
      //   if (val.length == 0) {
      //     isNull = true;
      //   }
      // });
      if (isNull) {
        Toast("请将信息补充完整！");
      } else {
        console.log(this.contract);
        let { data } = await consumerInfo(this.contract);
        if (data.success) {
          Toast.success(data.message);
          
           that.$store.commit("setContract", {});
          this.$router.push("/");
        } else {
          Toast.fail(data.message);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.info {
  height: 100vh;
  font-size: 15px;
  color: #333333;
  background-color: #f0f0f0;
  /deep/ .van-nav-bar {
    background: linear-gradient(-90deg, #5038fa, #1476ff);
    .van-nav-bar__title {
      color: #fff;
    }
  }
  /deep/ .van-nav-bar__left {
    .van-icon {
      color: #fff;
    }
  }
  .userInfo {
    .title {
      display: flex;
      align-items: center;
      margin: 12px 0 12px 16px;
      span {
        margin-left: 6px;
      }
    }
  }
  .button {
    width: 285px;
    margin: 30px auto;

    button {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
